@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;700&display=swap");

::-webkit-scrollbar {
  display: none;
}

body {
  height: 100vh;
  width: 100vw;
  background-color: #f5f5f5;
  font-family: "Space Grotesk", sans-serif;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 100ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

:root {
  --color-surface-50: #ffffff;
  --color-surface-100: #f7f9fd;
  --color-surface-150: #f5f5f5;
  --color-surface-200: #ecedee;
  --color-surface-250: #ebebeb;
  --color-surface-300: #cbcfd2;
  --color-surface-400: #a7aeb4;
  --color-surface-500: #8e979f;
  --color-surface-600: #6b767f;
  --color-surface-700: #4f575e;
  --color-surface-800: #33383d;
  --color-surface-900: #202327;

  --color-primary-50: #eae6ff;
  --color-primary-100: #d5cdff;
  --color-primary-200: #c0b3ff;
  --color-primary-300: #afa1ff;
  --color-primary-400: #9382ff;
  --color-primary-500: #7e6bff;
  --color-primary-600: #6455d6;

  --color-danger-50: #fbeaea;
  --color-danger-100: #e16b6b;
  --color-danger-200: #c92929;
  --color-danger-250: rgba(255, 91, 110, 1);
  --color-danger-300: #a92323;
  --color-danger-400: #6e1717;

  --color-secondary-100: rgba(85, 168, 217, 1);
  --color-secondary-200: rgba(64, 136, 179, 0.5);
  --color-secondary-300: rgba(85, 168, 217, 0.2);
  --color-secondary-400: rgba(85, 168, 217, 0.1);
  --color-secondary-500: rgba(85, 168, 217, 0.05);

  --color-success-50: #f6fefb;
  --color-success-100: #1bc590;
  --color-success-200: #12825f;
  --color-success-300: #0e674b;
  --color-success-400: #0a4834;

  --color-warning-50: #fefbcd;
  --color-warning-100: #fef69b;
  --color-warning-200: #fef069;
  --color-warning-300: #fdea43;
  --color-warning-400: #fde006;

  --color-opacity-primary: #ffb60026;
  --color-opacity-secondary: #00294126;
  --color-opacity-danger: #c9292926;
  --color-opacity-success: #12825f26;
  --color-opacity-warning: #f2ab5326;

  --color-background-modal: #121212eb;

  --color-dark-50: #121212;
  --color-dark-100: #161616;
  --color-dark-200: #1a1a1a;
  --color-dark-300: #1c1c1c;
  --color-dark-400: #1e1e1e;

  --space-0: 0px;
  --space-1: 4px;
  --space-2: 8px;
  --space-3: 12px;
  --space-4: 16px;
  --space-5: 20px;
  --space-6: 24px;
  --space-7: 28px;
  --space-8: 32px;
  --space-9: 36px;
  --space-10: 40px;
  --space-11: 44px;
  --space-12: 48px;
  --space-13: 56px;
  --space-14: 60px;
  --space-15: 64px;
  --space-16: 68px;
  --space-17: 80px;
  --space-18: 96px;

  --font-family-space-grotesk: "Space Grotesk";

  --font-size-heading-xl: 60px;
  --font-size-heading-lg: 60px;
  --font-size-heading-md: 34px;
  --font-size-title-xl: 24px;
  --font-size-title-lg: 20px;
  --font-size-body-xl: 18px;
  --font-size-body-lg: 16px;
  --font-size-body-md: 14px;
  --font-size-body-sm: 12px;
  --font-size-caption: 10px;

  --line-height-heading-xl: 48px;
  --line-height-heading-lg: 64px;
  --line-height-heading-md: 48px;
  --line-height-title-xl: 34px;
  --line-height-title-lg: 30px;
  --line-height-body-xl: 28px;
  --line-height-body-lg: 24px;
  --line-height-body-md: 20px;
  --line-height-body-sm: 18px;
  --line-height-caption: 14px;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
}
@layer components {
  .heading-xl-bold {
    @apply font-space-grotesk text-heading-xl font-bold;
  }
  .title-lg-bold {
    @apply font-space-grotesk text-title-lg font-bold;
  }
  .title-lg-medium {
    @apply font-space-grotesk text-title-lg font-medium;
  }
  .title-xl-bold {
    @apply font-space-grotesk text-title-xl font-bold;
  }
  .title-lg-bold {
    @apply font-space-grotesk text-title-lg font-bold;
  }
  .body-xl-bold {
    @apply font-space-grotesk text-body-xl font-bold;
  }
  .body-xl-medium {
    @apply font-space-grotesk text-body-xl font-medium;
  }
  .body-lg-bold {
    @apply font-space-grotesk text-body-lg font-bold;
  }
  .body-lg-medium {
    @apply font-space-grotesk text-body-lg font-medium;
  }
  .body-md-bold {
    @apply font-space-grotesk text-body-md font-bold;
  }
  .body-sm-bold {
    @apply font-space-grotesk text-body-sm font-bold;
  }
  .body-sm-medium {
    @apply font-space-grotesk text-body-sm font-medium;
  }
  .body-md-medium {
    @apply font-space-grotesk text-body-md font-medium;
  }
  .caption-bold {
    @apply font-space-grotesk text-caption font-bold;
  }
  .heading-xl {
    @apply font-space-grotesk text-heading-xl font-regular;
  }
  .title-lg {
    @apply font-space-grotesk text-title-lg font-regular;
  }
  .title-md {
    @apply font-space-grotesk text-title-md font-regular;
  }
  .title-xl {
    @apply font-space-grotesk text-title-xl font-regular;
  }
  .title-lg {
    @apply font-space-grotesk text-title-lg font-regular;
  }
  .body-xl {
    @apply font-space-grotesk text-body-xl font-regular;
  }
  .body-lg {
    @apply font-space-grotesk text-body-lg font-regular;
  }
  .body-md {
    @apply font-space-grotesk text-body-md font-regular;
  }
  .body-sm {
    @apply font-space-grotesk text-body-sm font-regular;
  }
  .caption {
    @apply font-space-grotesk text-caption font-regular;
  }
  .heading-md {
    @apply font-space-grotesk text-heading-md font-regular;
  }
  .heading-md-bold {
    @apply font-space-grotesk text-heading-md font-bold;
  }
  .heading-md-medium {
    @apply font-space-grotesk text-heading-md font-medium;
  }
}
